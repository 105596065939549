.profileConfigurationAvatar {
	background-color: rgb(153, 153, 153);
	
}

.profileConfigurationAvatar:hover {
	background-color: rgba(67, 67, 67, 0.669);
	-webkit-transition: background-color 1000ms linear;
	-ms-transition: background-color 1000ms linear;
	transition: background-color 1000ms linear;
}
  

.crop-container {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 80px;
	z-index: 999;
}
  
.controls {
	position: absolute;
	bottom: 0;
	left: 50%;
	width: 50%;
	transform: translateX(-50%);
	height: 80px;
	display: flex;
	align-items: center;
	z-index: 999;
}
.controls-background {
	bottom: 0;
	left: 0;
	height: 80px;
	position: absolute;
	z-index: 998;
	width: 100vw;
}
  
  