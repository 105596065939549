.blue-box {
  display: inline-block;
  background-color: blue;
  color: white;
  padding: 2px 4px;
  margin: 0 2px;
}

.tooltip {
  max-width: 90%!important;
  width: auto!important;
  position: fixed;
  bottom: 0px;
  left: 0px;
  background-color: #b3b3b3;
  color: #000000;
  padding: 5px;
  border-radius: 3px;
  display: none;
  z-index: 1000;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: x-small!important;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}


a {
  text-decoration: none;
  cursor: pointer; 
}
a:hover {
  text-decoration: underline;
}

