* {
	margin: 0;
  
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased!important;
  -moz-osx-font-smoothing: grayscale!important;
}
.MuiAccordionSummary-root{
  transition: none!important;
}

code {
  font-family: 'Open Sans', sans-serif;
}

.MuiDivider-root{
  border: none!important;
}
.MuiChip-root{
  background: none!important;
  color: none!important;
}
.MuiAppBar-root{
  box-shadow: none!important;
}