.content {
    height: 100%!important;
	background-image: linear-gradient(45deg, #4b6ddb, #4345C6);
}
.logo{
    z-index: 2!important;
    position: absolute!important;
    width: 15%!important;
    left: 5%!important;
    top: 5%!important;
    animation: 1.8s bounce-in-top;
    
}
.home-text {
    z-index: 2!important;
    position: absolute!important;
    left: 5%!important;
    max-width: 40%!important;
    padding-left: 45px!important;
    top: 15%!important;
    animation: 1.8s bounce-in-top;
    line-height: 2.3!important;

}
.gotoblog-text {
    position: relative!important;
    z-index: 2!important;
    text-align: center;
    top: 50%;
	color: #819fff!important;
}
.SkewedFooter{
    height: 300px;
    position: relative!important;
    top: 90%!important;
    width: 100%!important;
	transform: skewY(-5deg);
    animation: 1.8s bounce-in-top-skewed;
}

@keyframes bounce-in-top-skewed {
    0% {
        opacity: 1;
        transform: skewY(-5deg);
        transform: translateY(-180px);
    }
    60% {
        transform: translateY(20px);
        transform: skewY(-5deg);
    }
    80% { 
        transform: translateY(-8px); 
        transform: skewY(-5deg);
    }
    100% { 
        transform: translateY(0);
        transform: skewY(-5deg);
    }
}
@keyframes bounce-in-top {
    0% {
        opacity: 0;
        transform: translateY(-180px);
    }
    60% {
        transform: translateY(20px);
    }
    80% { 
        transform: translateY(-8px); 
    }
    100% { 
        transform: translateY(0);
        opacity: 1; 
    }
}  